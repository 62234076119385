<template>
  <top></top>
  <div class="container">
    <div class="row contentTop">
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-3 leftBd">
        <ul>
          <dt @click="notice">通知公告</dt>
          <dt @click="downloads">资料下载</dt>
          <dt @click="about">关于我们</dt>
          <dd></dd>
          <li><img :src="erwei" class="img-responsive center-block" /></li>
          <li>电话:010-57108081</li>
        </ul>
      </div>
      <div class="col-lg-10 col-md-10 col-sm-10 col-xs-9 minTop">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newTop">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>公示公告</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 newLst" >
            <dt>{{ noticeInfo.notice_title }}</dt>
            <span>
              <em>发布日期：{{ noticeInfo.create_time }}</em>
              <em>访问量：{{ noticeInfo.visits }}</em> 
            </span>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 sjText" >
            <div class="markDiv" v-html="noticeInfo.notice_text"></div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
  <bottom></bottom>
</template>

<script>
import { post } from "@/http/api";
import Top from "./top.vue";
import bottom from "./bottom.vue";
import erwei from "@/assets/erwei.png";

export default {
  name: "noticeInfo",
  components: {
    Top,
    bottom,
    erwei,
  },
  data() {
    return {
      erwei,
      noticeInfo:{},
    };
  },

  watch: {},

  created() {
    this.noticeInfos();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },

  methods: {
    noticeInfos() {
      const qs = require("qs");
      post(
        "Index/noticeInfo",
        qs.stringify({ notice_id: this.$route.params.notice_id})
      ).then((res) => {
        this.noticeInfo = res.noticeInfo;
      });
    },


    //跳转关于我们
    about(){
      this.$router.push({ path: `/About` });
      this.$forceUpdate();
    },
    
    //跳转资料下载
    downloads(){
      this.$router.push({ path: `/download` });
      this.$forceUpdate();
    },
    
    //跳转通知公告
    notice(){
      this.$router.push({ path: `/notice` });
      this.$forceUpdate();
    },


  },
};
</script> 
<style scoped>
@media (max-width: 768px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 3px;
    font-size: 1rem;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    font-weight: normal;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 0px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 0px;
    text-align: center;
    font-size: 1rem;
  }
  .leftBd ul li img {
    width: 80%;
  }
  :deep .el-breadcrumb {
    font-size: 1rem;
    line-height: 1;
  }
  /* 右侧 */
  .minTop {
    min-height: 365px;
  }
  .newTop {
    margin-bottom: 2%;
  }

  .newLst{
   text-align: center;
   margin-top:2%;
   padding:5px;
  }

  .newLst dt{
    font-size:1.1rem;
  }

  .newLst span em{
    font-size: 0.9rem;
    font-style: normal;
    padding:5px;
  }
  .markDiv{
    font-size: 1rem !important;
  }
}

@media (min-width: 768px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }

  .newLst{
   text-align: center;
  }

  .newLst dt{
    font-size: 18px;
  }

  .newLst span em{
    font-size: 12px;
    font-style: normal;
    padding:5px;
  }
  
}

@media (min-width: 992px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }

  .newLst{
   text-align: center;
  }

  .newLst dt{
    font-size: 18px;
  }

  .newLst span em{
    font-size: 12px;
    font-style: normal;
    padding:5px;
  }
  
}

@media (min-width: 1200px) {
  /* 左侧 */
  .contentTop {
    margin-top: 2%;
  }
  .leftBd {
    padding-left: 5px;
    padding-right: 5px;
  }
  .leftBd ul {
    border: 1px solid #3467ac;
    padding-left: 0px;
    border-radius: 3px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .leftBd ul dt {
    margin: 8px 3px;
    border: 1px solid #3467ac;
    padding: 8px;
    font-size: 14px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
  }
  .leftBd ul dt:hover {
    background: #296abc;
    color: #ffffff;
  }
  .leftBd ul dd {
    border-bottom: 1px solid #00000063;
    padding-bottom: 10px;
    margin: 5px 3px;
  }
  .leftBd ul li {
    list-style: none;
    margin: 8px 3px;
    text-align: center;
    font-size: 14px;
  }
  .leftBd ul li img {
    width: 80%;
  }
  /* 右侧 */
  .minTop {
    min-height: 600px;
  }
  .newTop {
    margin-bottom: 2%;
  }
  
  .newLst{
   text-align: center;
  }

  .newLst dt{
    font-size: 18px;
  }

  .newLst span em{
    font-size: 12px;
    font-style: normal;
    padding:5px;
  }
}
</style>